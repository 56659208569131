import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import About from './pages/about';
import Contact from './pages/contact';
import Resume from './pages/resume';
import ResumeP from './pages/resume_p';

function Body({page}) {
    useEffect(() => {
	const script = document.createElement('script');

	script.src = "scripts.js";
	script.async = true;

	document.body.appendChild(script);

	return () => {
	    document.body.removeChild(script);
	};
    });

    return (

            <Switch>
		<Route exact path="/">
		    <About />
		</Route>
		<Route path="/resume" component={Resume} />
		<Route path="/contact" component={Contact} />
		<Route path="/resumep" component={ResumeP} />
            </Switch>

    );
}

export default Body;
