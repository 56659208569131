import React, { useEffect } from 'react';

const LoadScript = scriptfile => {
    useEffect(() => {
	const script = document.createElement('script');

	script.src = scriptfile;
	script.async = true;

	document.body.appendChild(script);

	return () => {
	    document.body.removeChild(script);
	};
    });

    return (
	<div></div>
    );
}

export default LoadScript;
