import React from 'react';

function Resume() {
    return (
	    <div className="body-main">
		<div className="resume-main-content body-main-content">
		    <div className="resume-header">
			<div className="resume-header-name">
			    <h1>G. Ryan Spain</h1>
			</div>
			<div className="resume-download">
			    <a href="gryanspain_resume.pdf" download>
				<span className="im im-download"></span>
			    </a>
			</div>
		    </div>
		    <div className="resume-contact">
			    <span className="resume-contact-email">
				grspain@gmail.com
			    </span>
			    <span>&bull;</span>
			    <span className="resume-contact-phone">
				|704| 251-9875
			    </span>
			</div>
		    <div className="resume-main">
			<div className="resume-main-left body-main-left">

			    <div className="resume-profile resume-section">
				<h2>Profile</h2>
				<p>Driven, passionate software engineer, capable developing for both front end and back end, and adept at quickly learning new languages and technologies. Comfortable in  Agile and Scrum environments. Interests include functional and object-oriented programming, machine learning, and automation.</p>
			    </div>

			    <div className="resume-employment resume-section">
				<h2>Employment</h2>
				<h3>Devada</h3>

				<h4>Software Engineer</h4>
				<div className="resume-date"><p>2016-2019</p></div>
				<p>Co-created Java-based internal enterprise software used for campaign management, lead generation, email and asset creation, project pipeline management, mass email scheduling, and more using JSP, jQuery, Bootstrap, Maven, Freemarker, MySQL, Tomcat, and AWS S3.</p>
				<p>Worked with an Agile/Scrum team focused on feature development/maintenance of web application DZone.com, based on a Java and proprietry Spring-based  backend, with Groovy, Freemarker, Angular, jQuery, Tomcat, and AWS S3.</p>
				<p>Developed features including a recommendations engine for related articles; admin functionality and user-facing views for a new content type; and an updated user registration system.</p>
				<p>Spearheaded an initiative to update the DZone.com application's deployment process by migrating to Gradle, Docker, and Jenkins.</p>

				<h4>Director of Publications</h4>
				<div className="resume-date"><p>2015-2016</p></div>
				<p>Directed publication project pipelines, coordinated with contributors, and managed team members from publication inception to release.</p>

				<h4>Editor</h4>
				<div className="resume-date"><p>2014-2015</p></div>
				<p>Curated and contributed content for DZone.com.</p>


				<h3>North Carolina State University</h3>
				<h4>Instructor</h4>
				<div className="resume-date"><p>2012-2014</p></div>
				<p>Taught first-year writing and English composition.</p>

				<h3>Best Buy Geek Squad</h3>
				<h4>Computer Technician</h4>
				<div className="resume-date"><p>2010-2012</p></div>
				<p>Repaired computer software & hardware, served clients.</p>
			    </div>
			</div>

			<div className="resume-main-right body-main-right">
			    <div className="resume-skills resume-section">
				<h2>Skills</h2>
				
				<h3>Languages</h3>
				<p><strong>Proficient</strong>: 
				    Java
				    &bull;&nbsp;JavaScript
				    &bull;&nbsp;Python
				    &bull;&nbsp;Bash/Shell
				    &bull;&nbsp;HTML/CSS
				    &bull;&nbsp;Groovy</p>
				<p><strong>Familiar</strong>: 
				    Lisp
				    &bull;&nbsp;R
				    &bull;&nbsp;SQL
				    &bull;&nbsp;Haskell</p>

				<h3>Other Tools/Technologies</h3>
				<p><strong>Proficient</strong>: 
				    Git
				    &bull;&nbsp;Regex
				    &bull;&nbsp;Tomcat
				    &bull;&nbsp;React
				    &bull;&nbsp;Pandas
				    &bull;&nbsp;Docker
				    &bull;&nbsp;Jenkins
				    &bull;&nbsp;Org-Mode</p>
				<p><strong>Familiar</strong>: 
				    Angular
				    &bull;&nbsp;Django
				    &bull;&nbsp;JUnit / TestNG
				    &bull;&nbsp;jQuery
				    &bull;&nbsp;sklearn
				    &bull;&nbsp;Spring
				    &bull;&nbsp;Testim</p>

				<h3>Software/Services</h3>
				<p>Emacs
				    &bull;&nbsp;Excel
				    &bull;&nbsp;IntelliJ IDEA
				    &bull;&nbsp;InDesign
				    &bull;&nbsp;Google Analytics
				    &bull;&nbsp;PowerBI
				    &bull;&nbsp;Salesforce</p>

				<h3>Methodologies</h3>
				<p>Agile
				    &bull;&nbsp;Jira
				    &bull;&nbsp;Scrum</p>

				<h3>Other Skills</h3>
				<p>Teaching
				    &bull;&nbsp;Writing & Technical Writing
				    &bull;&nbsp;Data Science</p>
			    </div>

			    <div className="resume-education resume-section">
				<h2>Education</h2>
				<h3>MFA&nbsp;&bull; Poetry</h3>
				<h4>North Carolina State University</h4>
				<div className="resume-date"><p>2014</p></div>
				<p>Magna Cum Laude</p>

				<h3>BA&nbsp;&bull; English</h3>
				<h4>University of Alabama</h4>
				<div className="resume-date"><p>2009</p></div>

				<p>Summa Cum Laude&nbsp;&bull;
				    University Honors&nbsp;&bull;
				    Presidential Scholarship&nbsp;&bull;
				    ΣTΔ English Honors Society</p>
				<p>Minors in Creative Writing & Blount Undergraduate Initiative</p>
			    </div>
			    <div className="resume-publications resume-section">
				<h2>Selected Publications</h2>
				<p><em>R Essentials</em>, DZone.com</p>
				<p><em>Getting Started With Industrial Internet</em>, Co-Authored with Lothar Schubert, DZone.com</p>
			    </div>
			</div>
		    </div>
		</div>
	    </div>
    );
}

export default Resume;
