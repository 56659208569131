import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Header from './header';
import Body from './renderContent';

class Page extends React.Component {
    render() {
	return (
	    <BrowserRouter>
	      <Header />
	      <Body />
	    </BrowserRouter>
	);
    }
}

export default Page;
