import React from 'react';

function Contact() {
    
    return (
	<div className="body-main">
	    <div className="body-main-content">
		<div className="body-main-left contact">
		    <div className="welcome-preamble">
			Hi. I'm
		    </div>
		    <div className="welcome-main">
			G. Ryan Spain
		    </div>
		</div>
		
		<div className="contact-main-right body-main-right">
		    <div className="contact-main">
			<div className="contact-left">
			    <div className="headshot">
				<img src="g_ryan_spain.png" alt="G. Ryan Spain" />
			    </div>
			</div>
			<div className="contact-info">
			    <div className="contact-intro">G. Ryan Spain</div>
			    <div className="contact-info-line">
				<a href="mailto:grspain@gmail.com">
				    <span className="im im-mail"></span>grspain@gmail.com
				</a>
			    </div>
			    <div className="contact-info-line">
				<a href="tel:704-251-9875">
				    <span className="im im-phone"></span>|704| 251.9875
				</a>
			    </div>
			    <div className="contact-info-line">
				<a href="https://www.linkedin.com/in/grspain/"
				   target="_blank" rel="noopener noreferrer">
				    <span className="im im-linkedin"></span>/grspain
				</a>
			    </div>
			    {/*<div className="contact-info-line">
				<span className="im im-id-card"></span>.vcf
			    </div>*/}
			</div>
		    </div>
		</div>
	    </div>
	</div>
	
    );
}

export default Contact;
