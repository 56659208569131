import React from 'react';
import LoadScript from '../loadScript';

function About() {
    LoadScript("scripts.js");
    return (
	<div className="body-main">
	    <div className="body-main-content">
		<div className="body-main-left">
		    <div className="welcome-preamble">
			Hi. I'm
		    </div>
		    <div className="welcome-main">
			G. Ryan Spain
		    </div>
		</div>
		
		<div className="body-main-right">
		    <div className="about-left">
			I'm a&nbsp;
		    </div>
		    
		    <div className="about-right">
			<span className="about-right-line rainbow-list">full-stack software developer,</span>
			<span className="about-right-line rainbow-list">poet,</span>
			<span className="about-right-line rainbow-list">journeyman data scientist,</span>
			<span className="about-right-line rainbow-list">podcast enthusiast,</span>
			<span className="about-right-line rainbow-list">Legend of Zelda fan,</span>
			<span className="about-right-line rainbow-list">die-hard Emacs user,</span>
			<span className="about-right-line rainbow-list">Scotch lover,</span>
			<span className="about-right-line rainbow-list">and dungeon master.</span>
		    </div>
		</div>
	    </div>
	</div>
	
    );
}

export default About;
