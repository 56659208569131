import React from 'react';
import { NavLink } from 'react-router-dom';
import MobileDropdown from './mobile-dropdown';

class Header extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
	    isOpen: false
	};
	this.handleMenuClick = this.handleMenuClick.bind(this);
	this.handleItemClick = this.handleItemClick.bind(this);
    }
    
    blurOnKeyPress(e) {
	e.target.blur();
    }

    handleMenuClick(e) {
        e.preventDefault();
        this.setState({isOpen: !this.state.isOpen});
    }

    handleItemClick() {
	this.setState({isOpen: false});
    }
    
    render() {
	return (
    	    <div className="top-nav-main">
		<div className="top-nav-container">
		    <div className="main-logo-container">
			<NavLink exact to="/" className="logo-link" activeClassName="active">
			    <img src="logo.svg" className="main-logo" alt="logo" />
			</NavLink>
		    </div>
		    <div className="right-header-nav">
			<ul className="nav-main">
			    <li>
				<NavLink exact to="/" className="nav-link"
					 activeClassName="active"
					 onClick={(e) => this.blurOnKeyPress(e)}>
				    home
				</NavLink>
			    </li>
			    <li>
		                <NavLink to="/resume" className="nav-link"
					 activeClassName="active"
					 onClick={(e) => this.blurOnKeyPress(e)}>
				    resum&eacute;
				</NavLink>
			    </li>
			    <li>
				<NavLink to="/contact" className="nav-link"
					 activeClassName="active"
					 onClick={(e) => this.blurOnKeyPress(e)}>
				    contact
				</NavLink>
			    </li>
			</ul>
			<span className="nav-dropdown">
			    <span className="nav-dropdown-act">
				<span className="im im-menu" onClick={this.handleMenuClick}></span>
			    </span>
			    <MobileDropdown isOpen={this.state.isOpen} handleClick={this.handleItemClick}/>
			</span>
		    </div>
		</div>
	    </div>

	);
    }
}

export default Header;
