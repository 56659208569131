import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './fonts/stylesheet.css';
import Page from './pageWrapper';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Page />, document.getElementById('rct-page'));

serviceWorker.unregister();
