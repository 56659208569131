import React from 'react';
import { NavLink } from 'react-router-dom';

class MobileDropdown extends React.Component {
    constructor(props) {
	super(props);
	this.state = {
	    isOpen: false
	};
    }

    render() {
	if (this.props.isOpen) {
	    return (
		<div className="nav-drop-content">
		    <ul>
			<li>
			    <NavLink to="/" className="nav-link"
				     onClick={() => this.props.handleClick()}>
				home
			    </NavLink>
			</li>
			<li>
		            <NavLink to="/resume" className="nav-link"
				     onClick={() => this.props.handleClick()}>
				resum&eacute;
			    </NavLink>
			</li>
			<li>
			    <NavLink to="/contact" className="nav-link"
				     onClick={() => this.props.handleClick()}>
				contact
			    </NavLink>
			</li>
		    </ul>
		</div>
	    );
	} else {
	    return null;
	}

    }
}

export default MobileDropdown;
